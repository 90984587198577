// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-contact-tsx": () => import("./../../src/templates/contact.tsx" /* webpackChunkName: "component---src-templates-contact-tsx" */),
  "component---src-templates-instituicao-tsx": () => import("./../../src/templates/instituicao.tsx" /* webpackChunkName: "component---src-templates-instituicao-tsx" */),
  "component---src-templates-noticia-details-tsx": () => import("./../../src/templates/noticiaDetails.tsx" /* webpackChunkName: "component---src-templates-noticia-details-tsx" */),
  "component---src-templates-noticias-tsx": () => import("./../../src/templates/noticias.tsx" /* webpackChunkName: "component---src-templates-noticias-tsx" */)
}

